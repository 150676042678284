<template>
  <Page>
    <AppBar title="Invoice" />
    <loading-circle v-if="loading" />
    <v-snackbar v-model="snackbar" :timeout="3000" :color="color">
      {{ snackbarText }}
    </v-snackbar>
    <v-snackbar v-model="copyEmail" :timeout="3000" color="success" top right>
      Email copied successfully
    </v-snackbar>

    <v-container v-if="!loading" style="width: 100%" width="100%">
      <v-card tile style="padding: 10px; margin-top: 50px; margin-bottom: 50px">
        <div
          class="white--text align-start bg-primary d-flex flex-row justify-space-between"
          style="background-color: #1f6070; margin-bottom: 30px; width: 100%"
          width="100%"
          height="100px"
        >
          <v-card-title
            class="text-center justify-center"
            style="
              width: 100%;
              font-size: 20px !important;
              font-weight: 400 !important;
              align-self: flex-start;
            "
          >
            Invoice Payment
          </v-card-title>
        </div>

        <div>
          <v-row style="margin-top: 5px" v-if="logo">
            <v-col cols="6" class="text-left">
              <v-img :src="logo" max-width="200px" width="200px"></v-img>
            </v-col>

            <v-col cols="6" style="align: right" class="text-right"></v-col>
          </v-row>
        </div>
        <div class="mt-4">
          <v-row style="margin-top: 5px">
            <v-col cols="6" class="text-left">
              <p :style="styleCustomLeft">Invoice #: {{ invoice.id }}</p>
            </v-col>

            <v-col cols="6" style="align: right" class="text-right">
              <p :style="styleCustomRight">
                Charge Date:
                {{ invoice.duedate }}
              </p>
            </v-col>
          </v-row>
        </div>

        <div class="mt-4">
          <v-row style="margin-top: 5px">
            <v-col cols="6" class="text-left">
              <p :style="styleCustomLeft">Description</p>
            </v-col>

            <v-col cols="6" class="text-right">
              <p :style="styleCustomRight">Amount</p>
            </v-col>
          </v-row>
        </div>
        <div
          class="mt-4"
          style="border-top: 1px solid gray; margin-top: 5px  margin-left: 10px; margin-right: 10px;"
        >
          <v-row style="margin-top: 5px" v-if="invoice">
            <v-col cols="8" class="text-left">
              <p :style="styleCustomLeft">
                {{ invoice.description }}
              </p>
            </v-col>

            <v-col cols="4" class="text-right">
              <p class="text-right" :style="styleCustomRight">
                ${{ invoice.total ? invoice.total.toFixed(2) : '' }}
              </p>
            </v-col>
          </v-row>
        </div>
        <div
          class="mt-4"
          style="
            border-top: 1px solid gray;
            margin-top: 5px;
            margin-left: 10px;
            margin-right: 10px;
          "
        >
          <v-row style="margin-top: 5px">
            <v-col
              cols="6"
              style="display: flex; justify-content: space-around"
            >
              <p style="styleCustomLeft"></p>
            </v-col>

            <v-col cols="3" class="text-right">
              <p :style="styleCustomRight">Total</p>
            </v-col>

            <v-col cols="1" class="text-right" v-if="invoice">
              <p
                style="
                  font-weight: 600;
                  margin-bottom: 0;
                  align: right;
                  margin-right: 10px;
                "
              >
                ${{ invoice.total ? invoice.total.toFixed(2) : '' }}
              </p>
            </v-col>
          </v-row>
        </div>
        <div class="mt-4">
          <v-row style="margin-top: 5px">
            <v-col cols="12" class="text-center">
              <v-btn
                :loading="loading"
                color="green darken-2 white--text"
                :style="styleCustomButton"
                :disabled="
                  invoice.status.toUpperCase() === 'OPEN' ||
                  invoice.status.toUpperCase() === 'PAST DUE'
                    ? false
                    : true
                "
                @click="payInvoice"
              >
                Pay Now
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <!-- style="font-weight: 400; margin-bottom: 0; margin-top: 0px" -->
        <div style="margin-left: 10px; margin-bottom: 20px; margin-top: 30px">
          <p :style="styleCustomText">
            If you have any questions about this invoice, simply reply to this
            email or reach out to our support team for help.
          </p>

          <p :style="styleCustomText">Thank You,</p>
        </div>

        <div style="margin-left: 10px; margin-bottom: 20px; margin-top: 5px">
          <p
            v-if="facility"
            style="font-weight: 400; margin-bottom: 0; align: left"
          >
            {{ facility }}
          </p>
          <span v-if="emailClient">
            <input
              v-on:focus="$event.target.select()"
              ref="clone"
              readonly
              :style="styleCustomTextEmail"
              @click="copySucess"
              :value="emailClient"
            />
          </span>
          <p v-if="phone" @click="openTel" :style="styleCustomTextPhone">
            Phone: {{ phone }}
          </p>
        </div>
      </v-card>
      <PayInvoice
        @sucessPayment="sucessPayment"
        @errorPayment="errorPayment"
        ref="payInvoice"
        :cards="cards"
        :invoices="[]"
        :invoice="invoice"
        :showTransaction="showTransaction"
      />
    </v-container>
  </Page>
</template>
<style>
.mdi-close {
  visibility: hidden;
}
.v-btn--icon {
  visibility: hidden;
}
</style>
<script>
import axios from 'axios';
import { api, auth } from '../sharedPlugin';
import * as base64 from 'base-64';
import moment from 'moment';
import PayInvoice from '@/components/cryo/PayInvoice.vue';
import LoadingCircle from '@/components/LoadingCircle.vue';
import device from '../utils/device';

export default {
  components: { PayInvoice, LoadingCircle },
  data: () => ({
    styleCustomLeft:
      device() === 'mobile'
        ? {
            'font-size': '3.1vw',
            'font-weight': 400,
            align: 'left',
            'margin-bottom': 0,
            'margin-left': '10px'
          }
        : {
            align: 'left',
            'font-weight': 600,
            'margin-bottom': 0,
            'margin-left': '10px'
          },
    styleCustomRight:
      device() === 'mobile'
        ? {
            'font-size': '3.1vw',
            'font-weight': 400,
            'margin-bottom': 0,
            align: 'right',
            'margin-right': '10px'
          }
        : {
            'font-weight': 600,
            'margin-bottom': 0,
            align: 'right',
            'margin-right': '10px'
          },
    styleCustomButton:
      device() === 'mobile'
        ? {
            'font-size': '3.6vw',
            width: '200px',
            height: '60px'
          }
        : {
            width: '200px',
            height: '60px',
            'font-size': '20px'
          },
    styleCustomText:
      device() === 'mobile'
        ? {
            'font-size': '3.6vw',
            'font-weight': 400
          }
        : {
            'font-weight': 400
          },
    styleCustomTextPhone:
      device() === 'mobile'
        ? {
            cursor: 'pointer',
            color: '#4287f5',
            'font-size': '3.6vw',
            'font-weight': 400,
            'margin-bottom': ' 0 !important'
          }
        : {
            cursor: 'pointer',
            color: '#4287f5',
            'font-size': '16px',
            'font-weight': 400,
            'margin-bottom': ' 0 !important'
          },
    styleCustomTextEmail:
      device() === 'mobile'
        ? {
            width: '260px',
            height: '16px',
            border: 'none',
            'background-color': 'transparent',
            color: '#4287f5',
            'font-size': '3.6vw',
            'font-weight': 400,
            cursor: 'pointer'
          }
        : {
            width: '260px',
            height: '16px',
            border: 'none',
            'background-color': 'transparent',
            color: '#4287f5',
            'font-size': '16px',
            'font-weight': 400,
            cursor: 'pointer'
          },

    logo: null,
    showTransaction: true,
    loading: false,
    facility: null,
    emailClient: null,
    phone: null,
    invoiceLoading: false,
    invoice: null,
    cards: [],
    snackbarText: '',
    color: 'success',
    snackbar: false,
    copyEmail: false,
    email: 'cryoservices@preludefertility.com'
  }),
  created() {
    if (this.$route?.params?.token) {
      this.callMagicLinkEndpoint();
    }
  },
  methods: {
    ...auth.mapMethods(['logged']),

    sucessPayment() {
      this.snackbarText = 'Payment successful';
      this.color = 'success';
      this.snackbar = true;
      setTimeout(() => {
        this.snackbar = false;
      }, 2000);
    },
    errorPayment() {
      this.snackbarText = 'Payment failed . Update payment method please.';
      this.color = 'error';
      this.snackbar = true;
      setTimeout(() => {
        this.snackbar = false;
      }, 2000);
    },
    copySucess() {
      this.copyEmail = true;
      this.$refs.clone.focus();
      document.execCommand('copy');

      setTimeout(() => {
        this.copyEmail = false;
      }, 3000);
    },
    openTel() {
      window.open('tel:' + this.phone);
    },
    payInvoice() {
      this.$refs.payInvoice.show();
    },
    async callMagicLinkEndpoint() {
      this.loading = true;

      await axios
        .post(
          `${process.env.VUE_APP_API_URL}/api/v1/connect/auth/magicLinkLogin`,
          {
            id: this.$route?.params?.pid
          },
          {
            headers: {
              'x-api-key':
                '58b54801044df5a0536c25db11c653c743761b2db21e044aedb9f91caa6138a5'
            }
          }
        )
        .then(async (response) => {
          this.validate(response.data.token);
        })
        .catch((err) => {
          this.loading = false;
          console.error(err);
        });
    },
    async validationLink() {
      this.loading = true;
      await axios
        .post(
          `${process.env.VUE_APP_API_URL}/api/v1/connect/auth/tokenValidation`,
          {
            token: this.$route?.params?.token
          },
          {
            headers: {
              'x-api-key':
                '58b54801044df5a0536c25db11c653c743761b2db21e044aedb9f91caa6138a5'
            }
          }
        )
        .then((response) => {
          try {
            var pid = base64.decode(this.$route?.params?.pid);

            if (response && response.data && response.data.pid) {
              if (pid.toString() == response.data.pid.toString()) {
                this.loading = false;
                this.callMagicLinkEndpoint();
              } else {
                this.color = 'error';
                this.snackbarText = 'This link is invalid or expired';
                this.snackbar = true;

                setTimeout(() => {
                  this.snackbar = false;
                  this.$router.push({ name: 'Login' });
                }, 3000);
              }
            } else {
              this.color = 'error';
              this.snackbarText = 'This link is invalid or expired';
              this.snackbar = true;
              setTimeout(() => {
                this.snackbar = false;
                this.$router.push({ name: 'Login' });
              }, 3000);
            }
          } catch (error) {
            console.log('error:', error);
          }
        })
        .catch((err) => {
          this.loading = false;
          console.error(err);
        });
    },
    async validate(accessToken) {
      try {
        await this.logged(accessToken)
          .then((response) => {
            this.getClientLogo();
            this.getInvoice();
          })
          .catch((err) => {
            console.log('Error: ' + err);
          });
      } catch (error) {
        console.log('error validate logged:', error);
      }
    },
    async getClientLogo() {
      this.loading = true;
      await api.Users.getClientLogo()
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.facility = res.data[0]?.name;
            this.emailClient = res.data[0]?.email;
            this.phone = res.data[0]?.phone;
            this.logo = res.data[0].logoLink
              ? res.data[0].logoLink
              : res.data[0].logo;
          }
        })
        .finally(() => (this.loading = false));
    },
    async getInvoice() {
      this.loading = true;
      await api.Cryo.getCryoInvoice(base64.decode(this.$route?.params?.id))
        .then((res) => {
          this.invoice = res[0];

          if (this.invoice.duedate) {
            this.invoice.duedate = moment(this.invoice.duedate).format(
              'MM/DD/YYYY'
            );
          }

          if (this.invoice.balancedue) {
            this.invoice.amount = this.invoice.balancedue;
          } else {
            this.invoice.amount = this.invoice.total;
          }

          this.invoice.invoice = this.invoice.id;

          this.getPaymentMethod();
        })
        .finally(() => (this.loading = false));
    },
    getPaymentMethod() {
      api.CardConnect.getCardConnectProfile()
        .then(async (res) => {
          if (res.length > 0) {
            const cardProfiles = [...res];
            cardProfiles.forEach((cardProfile) => {
              this.cards.push({
                ...cardProfile,
                expiry: cardProfile.expires,
                account:
                  cardProfile.token.substr(1, 2) +
                  'XX XXXX XXXX ' +
                  cardProfile.last_four,
                cardType: cardProfile.description,
                lastfour: cardProfile.last_four
              });
            });
          }
        })
        .catch((err) => alert(err))
        .finally(() => (this.loadingEnrolled = false));
    }
  }
};
</script>
